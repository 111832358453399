import { Form, Input, Modal } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import {
  addMultiPurposeDashboardDashboard,
  setMultiPurposeDashboardActiveDashboard
} from '../../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import { MultiDashboard } from '../../../../redux/pages/dashboard/multiPurposeDashboard/types'
import { useBackend } from '../../../../services/backend'
import { Currency } from '../../../../types/currency/Currency'
import { DashboardPageType } from '../MultiPurposeDashboard'
import { DashboardRequest } from '../MultiPurposeDashboardProvider'
import { AppDispatch } from '../../../../redux/store'

interface Values {
  name: string
}

interface DashboardModalProps {
  visible: boolean
  multiPurposePage: DashboardPageType
  setVisible: (visible: boolean) => void
}

const initialParams: Partial<MultiDashboard> = {
  startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
  endDate: dayjs().endOf('year').format('YYYY-MM-DD'),
  currency: Currency.EUR,
  companies: [],
  dimensions: [],
  keyFigures: [],
  budgetingScenarios: [],
  formulas: []
}

const MultiPurposeDashboardModal: React.FC<DashboardModalProps> = ({ visible, multiPurposePage, setVisible }) => {
  const [form] = Form.useForm<Values>()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const { t } = useTranslation()
  const createDashboardRequest = useBackend(`api/${DashboardRequest[multiPurposePage]}`)

  const handleSave = async () => {
    form
      .validateFields()
      .then(async values => {
        createDashboardRequest
          .post({
            urlParams: {
              companyId: multiPurposePage !== DashboardPageType.companies ? companyId : null
            },
            body: {
              data: { ...initialParams, ...values }
            }
          })
          .then((res: MultiDashboard) => {
            if (res.id) {
              dispatch(addMultiPurposeDashboardDashboard(res))
              dispatch(setMultiPurposeDashboardActiveDashboard(res.id))
              form.resetFields()
            }
          })

        form.resetFields()
        setVisible(false)
      })
      .catch(info => {
        console.log('Validate Failed:', info)
      })
  }

  const handleCancel = () => {
    form.resetFields()
    setVisible(false)
  }

  return (
    <Modal
      title={t('dashboardPage:add-new-dashboard')}
      destroyOnClose
      open={visible}
      onCancel={handleCancel}
      onOk={handleSave}
      okText={t('global:save')}
      maskClosable={false}
      cancelText={t('global:cancel')}
      width="unset"
      style={{ maxWidth: 700 }}
      confirmLoading={createDashboardRequest.loading}
    >
      <Form
        form={form}
        name="multiPurposeDashboard"
        layout="vertical"
        onKeyUp={event => {
          // Enter
          if (event.code === 'Enter') {
            handleSave()
          }
        }}
      >
        <Form.Item name="id" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        {(multiPurposePage === DashboardPageType.compact || multiPurposePage === DashboardPageType.default) && (
          <Form.Item initialValue={multiPurposePage?.toUpperCase()} hidden name="subType">
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="name"
          label={t('global:name')}
          rules={[{ required: true, message: t('global:required-field') }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default MultiPurposeDashboardModal
