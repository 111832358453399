import { Select } from 'antd'
import { t } from 'i18next'
import React from 'react'
import { MultiDashboard } from '../../redux/pages/dashboard/multiPurposeDashboard/types'
import { CompanyDashboard } from '../../types/dashboard/company/types'

interface ReportSelectProps {
  activeDashboard?: number | null
  dashboards?: MultiDashboard[] | CompanyDashboard[]
  onChange: (id: number) => void
}

const ReportSelect = ({ activeDashboard, dashboards, onChange }: ReportSelectProps) => {
  const options = dashboards?.map(({ name, id }) => {
    return {
      label: name ?? t('global:default'),
      value: id
    }
  })

  return <Select value={activeDashboard} style={{ width: 250 }} options={options} onChange={onChange} />
}

export default ReportSelect
