import { Company } from '../../../../types/company/Company'
import { Currency } from '../../../../types/currency/Currency'
import { Dimension } from '../../../../types/dimension/Dimension'
import { Formula } from '../../../../types/formula/Formula'
import { Report, ReportRow } from '../../../context/reports/types'

export const ADD_MULTI_PURPOSE_DASHBOARD_DIMENSIONS = 'ADD_MULTI_PURPOSE_DASHBOARD_DIMENSIONS'
export const SET_MULTI_PURPOSE_DASHBOARD_ACTIVE_DASHBOARD = 'SET_MULTI_PURPOSE_DASHBOARD_ACTIVE_DASHBOARD'
export const SET_MULTI_PURPOSE_DASHBOARD_DASHBOARDS = 'SET_MULTI_PURPOSE_DASHBOARD_DASHBOARDS'
export const UPDATE_MULTI_PURPOSE_DASHBOARD_DASHBOARD = 'UPDATE_MULTI_PURPOSE_DASHBOARD_DASHBOARD'
export const ADD_MULTI_PURPOSE_DASHBOARD_DASHBOARD = 'ADD_MULTI_PURPOSE_DASHBOARD_DASHBOARD'
export const ADD_MULTI_PURPOSE_DASHBOARD_FORMULAS = 'ADD_MULTI_PURPOSE_DASHBOARD_FORMULAS'
export const SET_MULTI_PURPOSE_DASHBOARD_FORMULAS = 'SET_MULTI_PURPOSE_DASHBOARD_FORMULAS'
export const SET_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT = 'SET_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT'
export const RESET_MULTI_PURPOSE_DASHBOARD = 'RESET_MULTI_PURPOSE_DASHBOARD'
export const RESET_MULTI_PURPOSE_DASHBOARD_REPORTS = 'RESET_MULTI_PURPOSE_DASHBOARD_REPORTS'
export const SET_MULTI_PURPOSE_DASHBOARD_ORDER = 'SET_MULTI_PURPOSE_DASHBOARD_ORDER'
export const DELETE_MULTI_PURPOSE_DASHBOARD = 'DELETE_MULTI_PURPOSE_DASHBOARD'
export const SET_MULTI_PURPOSE_DASHBOARD_ALL_KEYFIGURE_STATEMENTS =
  'SET_MULTI_PURPOSE_DASHBOARD_ALL_KEYFIGURE_STATEMENTS'
export const ADD_MULTI_PURPOSE_DASHBOARD_DIMENSION_KEYFIGURE_STATEMENTS =
  'ADD_MULTI_PURPOSE_DASHBOARD_DIMENSION_KEYFIGURE_STATEMENTS'
export const SET_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT =
  'SET_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT'
export const SET_ALL_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT = 'SET_ALL_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT'
export const SET_ALL_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT =
  'SET_ALL_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT'
// Page data
export interface MultiPurposeDashboardPage {
  activeDashboard?: number
  dashboards: MultiDashboard[]
  keyfigureStatements: { [key: string]: ReportRow[] }
  internalKeyfigureStatements: { [key: string]: ReportRow[] }
  dimensionKeyfigureStatements: { [key: string]: ReportRow[] }
  formulas: { [key: string]: Formula[] }
  dimensions: { [key: string]: Dimension[] }
}
export interface MultiDashboard {
  id?: number
  name: string
  order: number
  userId: number
  startDate?: string
  endDate?: string
  currency?: Currency
  companies?: string[]
  dimensions?: string[]
  budgetingScenarios?: string[]
  keyFigures?: number[]
  formulas?: number[]
  subType?: 'COMPACT' | 'DEFAULT'
}

// Actions
export interface AddMultiPurposeDashboardFormulasAction {
  type: typeof ADD_MULTI_PURPOSE_DASHBOARD_FORMULAS
  payload: { companyId: Company['id']; formulas: Formula[] }
}

export interface AddMultiPurposeDashboardDimensionsAction {
  type: typeof ADD_MULTI_PURPOSE_DASHBOARD_DIMENSIONS
  payload: { companyId: Company['id']; dimensions: Dimension[] }
}

export interface SetMultiPurposeDashboardFormulasAction {
  type: typeof SET_MULTI_PURPOSE_DASHBOARD_FORMULAS
  payload: { [key: string]: Formula[] }
}

export interface ResetMultiPurposeDashboardAction {
  type: typeof RESET_MULTI_PURPOSE_DASHBOARD
}

export interface ResetMultiPurposeDashboardReportsAction {
  type: typeof RESET_MULTI_PURPOSE_DASHBOARD_REPORTS
}

export interface SetMultiPurposeDashboardsAction {
  type: typeof SET_MULTI_PURPOSE_DASHBOARD_DASHBOARDS
  payload: MultiDashboard[]
}

export interface SetMultiPurposeDashboardOrderAction {
  type: typeof SET_MULTI_PURPOSE_DASHBOARD_ORDER
  payload: { id: number; order: number }[]
}

export interface UpdateMultiPurposeDashboardAction {
  type: typeof UPDATE_MULTI_PURPOSE_DASHBOARD_DASHBOARD
  payload: MultiDashboard
}

export interface AddMultiPurposeDashboardAction {
  type: typeof ADD_MULTI_PURPOSE_DASHBOARD_DASHBOARD
  payload: MultiDashboard
}

export interface SetMultiPurposeDashboardsActiveDashboardAction {
  type: typeof SET_MULTI_PURPOSE_DASHBOARD_ACTIVE_DASHBOARD
  payload: number | undefined
}

export interface SetMultiPurposeDashboardKeyfigureStatementAction {
  type: typeof SET_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT
  payload: { id: string; data: Report }
}

export interface SetAllMultiPurposeDashboardKeyfigureStatementAction {
  type: typeof SET_ALL_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT
  payload: { dimensionId?: string; budgetingScenarioId?: number; data: Report }[]
}

export interface SetMultiPurposeDashboardInternalKeyfigureStatementAction {
  type: typeof SET_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT
  payload: { id: string; data: Report }
}

export interface SetAllMultiPurposeDashboardInternalKeyfigureStatementAction {
  type: typeof SET_ALL_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT
  payload: { dimensionId: string; data: Report }[]
}

export interface SetMultiPurposeDashboardAllKeyfigureStatementsAction {
  type: typeof SET_MULTI_PURPOSE_DASHBOARD_ALL_KEYFIGURE_STATEMENTS
  payload: { [key: string]: ReportRow[] }
}

export interface AddMultiPurposeDashboardDimensionKeyfigureStatementsAction {
  type: typeof ADD_MULTI_PURPOSE_DASHBOARD_DIMENSION_KEYFIGURE_STATEMENTS
  payload: { [key: string]: ReportRow[] }
}

export interface DeleteMultiPurposeDashboardAction {
  type: typeof DELETE_MULTI_PURPOSE_DASHBOARD
  payload: number
}

export type MultiPurposeDashboardActionTypes =
  | AddMultiPurposeDashboardFormulasAction
  | SetMultiPurposeDashboardsAction
  | UpdateMultiPurposeDashboardAction
  | SetMultiPurposeDashboardsActiveDashboardAction
  | SetMultiPurposeDashboardKeyfigureStatementAction
  | ResetMultiPurposeDashboardAction
  | AddMultiPurposeDashboardAction
  | ResetMultiPurposeDashboardReportsAction
  | SetMultiPurposeDashboardOrderAction
  | DeleteMultiPurposeDashboardAction
  | SetMultiPurposeDashboardAllKeyfigureStatementsAction
  | SetMultiPurposeDashboardFormulasAction
  | AddMultiPurposeDashboardDimensionKeyfigureStatementsAction
  | AddMultiPurposeDashboardDimensionsAction
  | SetMultiPurposeDashboardInternalKeyfigureStatementAction
  | SetAllMultiPurposeDashboardKeyfigureStatementAction
  | SetAllMultiPurposeDashboardInternalKeyfigureStatementAction
