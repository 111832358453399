import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Space } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import { useBackend } from '../../../services/backend'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import MultiPurposeDashboardFormulaCharts from './components/MultiPurposeDashboardFormulaCharts'
import MultiPurposeParamsForm from './form/MultiPurposeParamsForm'
import MultiPurposeDashboardProvider, { DashboardRequest } from './MultiPurposeDashboardProvider'
import MultiPurposeDashboardDefaultSections from './MultiPurposeDashboardDefaultSections'
import { contextCompanySelector } from '../../../redux/context/company/selectors'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { getDashboardData } from './utils'
import { currentUserSortedCompaniesSelector } from '../../../redux/session/currentUser/selectors'
import { dimensionTreeSelector } from '../../../redux/context/dimensions/selectors'
import {
  multiPurposeActiveDashboardSelector,
  multiPurposeDashboardPageSelector,
  multiPurposeDashboardsSelector
} from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import {
  resetMultiPurposeDashboard,
  setAllMultiPurposeDashboardKeyfigureStatement,
  setMultiPurposeDashboardActiveDashboard,
  setMultiPurposeDashboardDashboards
} from '../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import { Report, ReportDataType, ReportRow } from '../../../redux/context/reports/types'
import { MultiDashboard } from '../../../redux/pages/dashboard/multiPurposeDashboard/types'
import { Dimension, DimensionQueryOperator } from '../../../types/dimension/Dimension'
import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'
import MultiPurposeDashboardTable from './components/MultiPurposeDashboardTable'
import MultiPurposeDashboardKeyFigureCharts from './components/MultiPurposeDashboardKeyFigureCharts'
import MultiPurposeDashboardModal from './components/MultiPurposeDashboardModal'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { getCompanysKeyFigureIds } from '../../../utils/keyFigures'
import MultiToolbar from '../multiCompanyDashboard/MultiCompanyToolbar'
import NoDashboards from './components/NoDashboards'
import { AppDispatch } from '../../../redux/store'
import ReportSelect from '../../../components/Misc/ReportSelect'

export enum DashboardPageType {
  default = 'default',
  compact = 'compact',
  scenarios = 'scenarios',
  companies = 'companies'
}

interface MultiPurposeDashboardProps {
  keyfigureStatements: { [key: string]: ReportRow[] }
  selectedKeyfigureIds: number[]
  selectedInternalKeyfigureIds: number[]
  allRows: Dimension[] | BudgetingScenario[]
  selectedRows?: string[]
}

const MultiPurposeDashboard: React.FC<MultiPurposeDashboardProps> = ({
  keyfigureStatements,
  selectedKeyfigureIds,
  selectedInternalKeyfigureIds,
  allRows,
  selectedRows
}) => {
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const { activeDashboard } = useSelector(multiPurposeDashboardPageSelector)
  const dashboards = useSelector(multiPurposeDashboardsSelector)
  const [modalVisible, setModalVisible] = useState(false)
  const currentPage = location.pathname.split('/').pop() as DashboardPageType
  const dashboardsRequest = useBackend(`api/${DashboardRequest[currentPage]}`)
  const [showCharts, setShowCharts] = useState(false)
  const [loading, setLoading] = useState(false)
  const company = useSelector(contextCompanySelector)
  const activeDashboardData = useSelector(multiPurposeActiveDashboardSelector)
  const statementsRequest = useBackend(`/api/companies/{contextCompany}/reporting/custom`)
  const { budgetingScenario, periodGroups, dimensions } = useSelector(filtersSelector)
  const contextCompany = useSelector(contextCompanySelector)
  const prevPrediodGroups = useRef<PeriodGroup[]>()
  const myCompanies = useSelector(currentUserSortedCompaniesSelector)
  const dimensionTree = useSelector(dimensionTreeSelector)
  const multiPurposeDashboard = useSelector(multiPurposeDashboardPageSelector)

  const onChange = (id: number) => {
    dispatch(setMultiPurposeDashboardActiveDashboard(id))
  }

  const contentEl = useRef(null)

  useEffect(() => {
    if (company) {
      dashboardsRequest
        .get({
          urlParams: {
            companyId: currentPage !== DashboardPageType.companies ? company.id : null
          }
        })
        .then((res: MultiDashboard[]) => {
          let responseDashboards = res
          if (currentPage === DashboardPageType.default || currentPage === DashboardPageType.compact) {
            responseDashboards = responseDashboards.filter(
              (d: MultiDashboard) => d.subType === currentPage?.toUpperCase()
            )
          }
          dispatch(setMultiPurposeDashboardDashboards(responseDashboards))
          responseDashboards?.[0]?.id && dispatch(setMultiPurposeDashboardActiveDashboard(responseDashboards?.[0].id))
        })
        .catch()
    }
    return () => {
      dispatch(resetMultiPurposeDashboard())
    }
  }, [company])

  const getKeyFigureStatementData = async (dimensionIds?: string[], budgetingScenarioId?: number) => {
    if (contextCompany && (dimensionIds || budgetingScenarioId)) {
      const data = statementsRequest.get({
        urlParams: { contextCompany: contextCompany.id },
        body: {
          params: {
            keyFigureIds: getCompanysKeyFigureIds(company, selectedKeyfigureIds),
            formulaIds: selectedInternalKeyfigureIds,
            budgetingScenarioId: currentPage !== DashboardPageType.companies && budgetingScenarioId,
            dimensions: JSON.stringify(
              dimensionIds?.map(id => ({
                k: id,
                o: DimensionQueryOperator.has,
                v: id
              }))
            ),
            periodGroups,
            dataTypes: [ReportDataType.actuals, ReportDataType.budget]
          }
        }
      })
      return data
    }
    return null
  }

  const getData = async () => {
    if (selectedRows && (currentPage === DashboardPageType.default || currentPage === DashboardPageType.scenarios)) {
      prevPrediodGroups.current = periodGroups
      setLoading(true)
      let promises
      if (currentPage === DashboardPageType.scenarios) {
        // skenaariot mäpättävä yksitellen
        promises = selectedRows.map(rowId => {
          return getKeyFigureStatementData(dimensions, +rowId)
            .then((res: any) => {
              return {
                data: res?.[0]?.data,
                budgetingScenarioId: rowId
              }
            })
            .catch(console.log)
        })
      } else {
        // dimensiot saa yhdellä requestilla
        promises = await getKeyFigureStatementData(selectedRows, budgetingScenario && budgetingScenario.id)
          .then((res: any) => {
            return res
          })
          .catch(console.log)
      }

      const keyfigureStatementDatas = await Promise.all(promises)

      if (keyfigureStatementDatas.length > 0) {
        dispatch(
          setAllMultiPurposeDashboardKeyfigureStatement(
            keyfigureStatementDatas as {
              data: Report
              dimensionKey?: string
              budgetingScenarioId?: number
            }[]
          )
        )
      }

      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [periodGroups, selectedRows, selectedKeyfigureIds, selectedInternalKeyfigureIds])

  const content = () => {
    switch (currentPage) {
      case DashboardPageType.compact:
        return (
          <>
            <MultiPurposeDashboardTable
              tableData={getDashboardData(
                {
                  ...activeDashboardData,
                  companies: [company?.id]
                } as MultiDashboard,
                multiPurposeDashboard.keyfigureStatements,
                multiPurposeDashboard.dimensionKeyfigureStatements,
                multiPurposeDashboard.dimensions,
                myCompanies,
                periodGroups,
                dimensionTree,
                DashboardPageType.compact
              )}
            />
            {showCharts && (
              <>
                <MultiPurposeDashboardKeyFigureCharts />
                <MultiPurposeDashboardFormulaCharts />
              </>
            )}
          </>
        )
      case DashboardPageType.default:
      case DashboardPageType.scenarios:
        return (
          <MultiPurposeDashboardDefaultSections
            showCharts={showCharts}
            keyfigureStatements={keyfigureStatements}
            selectedKeyfigureIds={selectedKeyfigureIds}
            selectedInternalKeyfigureIds={selectedInternalKeyfigureIds}
            allRows={allRows}
            selectedRows={selectedRows}
            dashboardType={currentPage === DashboardPageType.scenarios ? 'scenarios' : 'dimensions'}
          />
        )

      default:
        return undefined
    }
  }

  const pageHeaderTitle = () => {
    switch (currentPage) {
      case DashboardPageType.scenarios:
        return `${t('menu:/dashboard')} - ${t('menu:/dashboard/scenarios')}`
      default:
        return `${t('menu:/dashboard')} - ${t('menu:/dashboard/dimensions')} ${t(`dashboardPage:${currentPage}`)}`
    }
  }

  return (
    <MultiPurposeDashboardProvider multiPurposeDashboardPage={currentPage}>
      <PageHeaderWrapper
        loading={dashboardsRequest.loading}
        title={pageHeaderTitle()}
        subTitle={
          <DocumentationLink
            route={{
              path:
                currentPage === DashboardPageType.compact || currentPage === DashboardPageType.default
                  ? '/dashboard/dimensions'
                  : undefined
            }}
          />
        }
        ghost={false}
        extra={[
          <ReportSelect activeDashboard={activeDashboard} dashboards={dashboards} onChange={onChange} />,
          <Space>
            <Button icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
              {t('dashboardPage:new-dashboard')}
            </Button>
          </Space>
        ]}
        tabActiveKey={activeDashboard?.toString()}
        onTabChange={key => {
          dispatch(setMultiPurposeDashboardActiveDashboard(+key))
        }}
        content={activeDashboard && <MultiPurposeParamsForm setLoading={setLoading} />}
      >
        <LoadingWrapper loading={loading}>
          <MultiPurposeDashboardModal
            multiPurposePage={currentPage}
            visible={modalVisible}
            setVisible={setModalVisible}
          />
          {activeDashboard ? (
            <Card>
              <MultiToolbar
                toggleCharts={val => setShowCharts(val)}
                element={contentEl}
                exportName={activeDashboardData?.name}
              />
              <div ref={contentEl}>{content()}</div>
            </Card>
          ) : (
            <NoDashboards onClick={() => setModalVisible(true)} />
          )}
        </LoadingWrapper>
      </PageHeaderWrapper>
    </MultiPurposeDashboardProvider>
  )
}

export default MultiPurposeDashboard
