import _ from 'lodash'
import i18next from 'i18next'
import dayjs from 'dayjs'
import { ReportTableRow } from '../../../components/Table/types'
import { ReportRow, ReportType } from '../../../redux/context/reports/types'
import { Company, Country } from '../../../types/company/Company'
import { Dimension } from '../../../types/dimension/Dimension'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { getTableRows } from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import { MultiDashboard } from '../../../redux/pages/dashboard/multiPurposeDashboard/types'
import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'
import { Tab } from '../../../types/tab/tab'

export enum DashboardPageType {
  default = 'default',
  compact = 'compact',
  scenarios = 'scenarios',
  companies = 'companies'
}

export const generateRowTitle = (
  type: string,
  allRows: any[],
  dId: string,
  row: ReportTableRow,
  dashboardType: 'scenarios' | 'dimensions'
) => {
  if (dashboardType === 'dimensions') {
    return type === 'keyFigure'
      ? _.keyBy(allRows, 'dimensionId')[dId] &&
          `${i18next.t(`keyFigureStatement:${row.id}`)} - ${_.keyBy(allRows, 'dimensionId')[dId]?.name}`
      : `${row.name} - ${_.keyBy(allRows, 'dimensionId')[dId]?.name}`
  }
  return type === 'keyFigure'
    ? _.keyBy(allRows, 'id')[dId] &&
        `${i18next.t(`keyFigureStatement:${row.id}`)} - ${_.keyBy(allRows, 'id')[dId]?.name}`
    : `${row.name} - ${_.keyBy(allRows, 'id')[dId]?.name}`
}

export const getKeyfigureRows = (
  ids: number[],
  type: string,
  keyfigureOrFormulaStatement: { [key: string]: ReportRow[] },
  allRows: Dimension[] | BudgetingScenario[],
  selectedRows: string[],
  dashboardType: 'scenarios' | 'dimensions'
) => {
  const rows = selectedRows.map((dId: string) =>
    keyfigureOrFormulaStatement && keyfigureOrFormulaStatement[dId]
      ? keyfigureOrFormulaStatement[dId]
          .filter((row: ReportTableRow) => ids.includes(row.id))
          .map((row: ReportTableRow) => {
            const rowTitle = generateRowTitle(type, allRows, dId, row, dashboardType)
            return {
              ...row,
              id: `${dId}${row.id}`,
              customTitle: rowTitle,
              report: ReportType.keyFigureStatement
            }
          })
      : []
  )

  const flatRows = _.flatten(_.zip(...rows)).filter(row => row !== undefined)

  return (flatRows || []) as unknown as ReportRow[]
}

export const getDashboardStartAndEndDates = (
  dashboard: MultiDashboard,
  periodGroups?: PeriodGroup[],
  dashboardType?: DashboardPageType
) => {
  let { startDate } = dashboard
  let { endDate } = dashboard
  if (dashboardType === DashboardPageType.compact || dashboard.subType === DashboardPageType.compact.toUpperCase()) {
    startDate = dayjs().startOf('year').format('YYYY-MM-DD')
    endDate = dayjs().endOf('year').format('YYYY-MM-DD')
    if (periodGroups && periodGroups.length === 1) {
      startDate = periodGroups[0].startDate
      endDate = periodGroups[0].endDate
    }
  }
  return { startDate, endDate }
}

export const getDashboardData = (
  activeDashboard: MultiDashboard | undefined,
  keyfigureStatements: { [key: string]: ReportRow[] },
  dimensionsKeyfigureStatements: { [key: string]: ReportRow[] },
  dimensions: { [key: string]: Dimension[] },
  myCompanies: Company[],
  periodGroups?: PeriodGroup[],
  dimensionTree?: Dimension[],
  multiPurposeDashboardPage?: DashboardPageType
) => {
  if (!activeDashboard) return []
  const { startDate, endDate } = getDashboardStartAndEndDates(activeDashboard, periodGroups, multiPurposeDashboardPage)
  const tableRows =
    activeDashboard?.companies
      ?.filter((id: string) => !!myCompanies.find(c => c.id === id))
      .map((id: string) => {
        const company = myCompanies.find(c => c.id === id)
        const report: ReportRow[] = keyfigureStatements[id]
        const tableRow: any = {
          ...company,
          // jos saa propsina dimensiontreen -> chilreniksi pelkästään se (dimensiondashboard)
          children: dimensionTree || (company && dimensions?.[company.id]) || undefined
        }
        const map = ({ children, ...o }: ReportRow): ReportRow => {
          const rows: ReportRow[] = dimensionsKeyfigureStatements[o.id]

          return {
            company,
            ...o,
            ...getTableRows(rows || [], {
              startDate,
              endDate
            } as MultiDashboard),
            children: children?.map(map) // recursive call
          }
        }

        return {
          ...tableRow,
          ...getTableRows(report || [], {
            startDate,
            endDate
          } as MultiDashboard),
          children: tableRow.children?.map(map)
        }
      }) || []

  return tableRows
}

export const vatIdSlicer = (vatId: string, company: Company) => {
  if (company.country === Country.fi) {
    return `${vatId.slice(0, -1)}-${vatId.slice(-1)}`
  }
  if (company.country === Country.se) {
    return `${vatId.slice(0, -4)}-${vatId.slice(-4)}`
  }
  return `${vatId.slice(0, -1)}-${vatId.slice(-1)}`
}

export const flattenObject = (obj: { [key: string]: any[] }) => {
  return Object.values(obj).flat()
}

export const generateTabs = (dbs: MultiDashboard[]): Tab[] => {
  return dbs.map(({ id, name }) => ({
    key: id?.toString() || '',
    label: name
  }))
}
