import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Space } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import {
  resetMultiPurposeDashboard,
  resetMultiPurposeDashboardReports,
  setMultiPurposeDashboardActiveDashboard,
  setMultiPurposeDashboardAndActiveKey
} from '../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import {
  multiPurposeDashboardPageSelector,
  multiPurposeActiveDashboardSelector,
  multiPurposeDashboardsSelector
} from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import { useBackend } from '../../../services/backend'

import MultiPurposeDashboardFormulaCharts from '../multiPurposeDashboard/components/MultiPurposeDashboardFormulaCharts'
import MultiPurposeParamsForm from '../multiPurposeDashboard/form/MultiPurposeParamsForm'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import MultiPurposeDashboardProvider from '../multiPurposeDashboard/MultiPurposeDashboardProvider'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import { getDashboardData } from '../multiPurposeDashboard/utils'
import { currentUserSortedCompaniesSelector } from '../../../redux/session/currentUser/selectors'
import { DashboardPageType } from '../multiPurposeDashboard/MultiPurposeDashboard'
import MultiPurposeDashboardTable from '../multiPurposeDashboard/components/MultiPurposeDashboardTable'
import MultiPurposeDashboardKeyFigureCharts from '../multiPurposeDashboard/components/MultiPurposeDashboardKeyFigureCharts'
import MultiPurposeDashboardModal from '../multiPurposeDashboard/components/MultiPurposeDashboardModal'
import MultiToolbar from './MultiCompanyToolbar'
import NoDashboards from '../multiPurposeDashboard/components/NoDashboards'
import { AppDispatch } from '../../../redux/store'
import { contextCompanySelector } from '../../../redux/context/company/selectors'
import MultiPurposeDashboardTableTwo from '../multiPurposeDashboard/components/MultiPurposeDashboardTableTwo'
import ReportSelect from '../../../components/Misc/ReportSelect'

const MultiCompanyDashboard: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const { activeDashboard } = useSelector(multiPurposeDashboardPageSelector)
  const dashboards = useSelector(multiPurposeDashboardsSelector)
  const [modalVisible, setModalVisible] = useState(false)
  const company = useSelector(contextCompanySelector)
  const dashboardsRequest = useBackend('api/users/current/dashboards/multi-company')
  const [showCharts, setShowCharts] = useState(false)
  const [toggleRowsAndColumns, setToggleRowsAndColumns] = useState(false)
  const multiPurposeDashboard = useSelector(multiPurposeDashboardPageSelector)
  const myCompanies = useSelector(currentUserSortedCompaniesSelector)
  const activeDashboardData = useSelector(multiPurposeActiveDashboardSelector)
  const [loading, setLoading] = useState(false)
  const tableEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    dashboardsRequest
      .get()
      .then((res: any) => {
        res.length > 0 && dispatch(setMultiPurposeDashboardAndActiveKey(res))
        res?.[0]?.id && dispatch(setMultiPurposeDashboardActiveDashboard(res?.[0].id))
      })
      .catch()
    return () => {
      dispatch(resetMultiPurposeDashboard())
    }
  }, [company])

  const onChangge = (id: number) => {
    dispatch(resetMultiPurposeDashboardReports())
    dispatch(setMultiPurposeDashboardActiveDashboard(id))
  }

  return (
    <MultiPurposeDashboardProvider multiPurposeDashboardPage={DashboardPageType.companies}>
      <PageHeaderWrapper
        loading={dashboardsRequest.loading}
        title={`${t('menu:/dashboard')} - ${t(`menu:/dashboard/${location.pathname.split('/').slice(-1)[0]}`)}`}
        subTitle={<DocumentationLink />}
        ghost={false}
        extra={[
          <ReportSelect onChange={onChangge} dashboards={dashboards} activeDashboard={activeDashboard} />,
          <Space>
            <Button icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
              {t('dashboardPage:new-dashboard')}
            </Button>
          </Space>
        ]}
        // onOrderChange={handleOrderChange}
        content={activeDashboard && <MultiPurposeParamsForm setLoading={setLoading} />}
      >
        <LoadingWrapper loading={loading}>
          <MultiPurposeDashboardModal
            multiPurposePage={DashboardPageType.companies}
            visible={modalVisible}
            setVisible={setModalVisible}
          />
          {activeDashboard ? (
            <Card>
              <MultiToolbar
                toggleCharts={val => setShowCharts(val)}
                toggleRowsAndColumns={val => setToggleRowsAndColumns(val)}
                element={tableEl}
                exportName={activeDashboardData?.name}
              />
              <div ref={tableEl}>
                {toggleRowsAndColumns ? (
                  <MultiPurposeDashboardTableTwo
                    tableData={getDashboardData(
                      activeDashboardData,
                      multiPurposeDashboard.keyfigureStatements,
                      multiPurposeDashboard.dimensionKeyfigureStatements,
                      multiPurposeDashboard.dimensions,
                      myCompanies
                    )}
                  />
                ) : (
                  <MultiPurposeDashboardTable
                    tableData={getDashboardData(
                      activeDashboardData,
                      multiPurposeDashboard.keyfigureStatements,
                      multiPurposeDashboard.dimensionKeyfigureStatements,
                      multiPurposeDashboard.dimensions,
                      myCompanies
                    )}
                  />
                )}
              </div>
              {showCharts && (
                <>
                  <MultiPurposeDashboardKeyFigureCharts />
                  <MultiPurposeDashboardFormulaCharts />
                </>
              )}
            </Card>
          ) : (
            <NoDashboards onClick={() => setModalVisible(true)} />
          )}
        </LoadingWrapper>
      </PageHeaderWrapper>
    </MultiPurposeDashboardProvider>
  )
}
export default MultiCompanyDashboard
