import { Collapse } from 'antd'
import _ from 'lodash'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { contextCompanySelector } from '../../../../redux/context/company/selectors'
import { dimensionListSelector } from '../../../../redux/context/dimensions/selectors'
import { filtersSelector } from '../../../../redux/context/filters/selectors'
import { Balance } from '../../../../redux/context/reports/types'
import { keyFiguresSelector } from '../../../../redux/entities/keyFigures/selectors'
import {
  multiPurposeDashboardPageSelector,
  multiPurposeDashboardSelector
} from '../../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import { MultiDashboard } from '../../../../redux/pages/dashboard/multiPurposeDashboard/types'
import { currentUserSortedCompaniesSelector } from '../../../../redux/session/currentUser/selectors'
import { PeriodGroup } from '../../../../types/periodGroup/PeriodGroup'
import { formatValueToNumberByType } from '../../../../utils/helpers'
import { getCompanysKeyFigureId } from '../../../../utils/keyFigures'
import CompetitorChart from '../../../industryAndCompetitorComparison/components/Chart'
import { getDashboardStartAndEndDates } from '../utils'
import { generatePeriodGroupPeriods } from '../../../../utils/periodGroups/utils'

const MultiPurposeDashboardKeyFigureCharts = () => {
  const { keyfigureStatements, dimensionKeyfigureStatements } = useSelector(multiPurposeDashboardPageSelector)
  const { t } = useTranslation()
  const myCompanies = useSelector(currentUserSortedCompaniesSelector)
  const keyfigures = useSelector(keyFiguresSelector).filter(kf => kf.monthly)
  const dimensions = useSelector(dimensionListSelector)
  const dashboard = useSelector(multiPurposeDashboardSelector)
  const contextCompany = useSelector(contextCompanySelector)
  const { periodGroups } = useSelector(filtersSelector)

  const { startDate, endDate } = getDashboardStartAndEndDates(
    dashboard ||
      ({
        startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
        endDate: dayjs().endOf('year').format('YYYY-MM-DD')
      } as MultiDashboard),
    periodGroups
  )

  const generateKeyFigureChartSeries = (globalKeyFigureId?: number) => {
    const series: any = []
    if (!globalKeyFigureId) return series
    // Make series
    let mapCompanies = dashboard?.companies || []
    if (contextCompany && mapCompanies?.length < 1) {
      mapCompanies = [contextCompany.id]
    }
    mapCompanies
      ?.filter((id: string) => myCompanies.find(c => c.id === id))
      .forEach((companyId: string) => {
        const company = myCompanies?.find(c => c.id === companyId)
        const keyFigureId = getCompanysKeyFigureId(company, globalKeyFigureId)
        const keyFigure = keyfigures?.find(k => k.id === keyFigureId)
        const serie = {
          name: company?.name,
          type: 'column',
          data: [] as (number | null)[]
        }
        if (!keyFigure || !company) return
        if (keyfigureStatements[companyId]) {
          const { actuals } = _.keyBy(keyfigureStatements[companyId], 'id')[globalKeyFigureId] || []
          for (const { month, year } of generatePeriodGroupPeriods({
            id: dayjs(startDate).year(),
            startDate: startDate || '',
            endDate: endDate || ''
          } as PeriodGroup)) {
            const foundBalance =
              actuals && actuals.find((balance: Balance) => balance.year === year && balance.month === month)
            serie.data.push(foundBalance ? formatValueToNumberByType(foundBalance.value, keyFigure.type) : 0)
          }
        }
        series.push(serie)
      })

    if (dimensionKeyfigureStatements && mapCompanies.length < 2) {
      Object.entries(dimensionKeyfigureStatements).forEach(([dimensionId, dimensionDatas]: any) => {
        dimensionDatas
          ?.filter((dimData: any) => dimData.id === globalKeyFigureId)
          .forEach((dimensionData: any) => {
            const dimension = dimensions?.find(c => c.id === dimensionId)
            const serie = {
              name: dimension?.name,
              type: 'column',
              data: [] as (number | null)[]
            }
            if (!dimensionData || !dimension) return
            const { actuals } = dimensionData
            for (const { month, year } of generatePeriodGroupPeriods({
              id: dayjs(startDate).year(),
              startDate: startDate || '',
              endDate: endDate || ''
            } as PeriodGroup)) {
              const foundBalance =
                actuals && actuals.find((balance: Balance) => balance.year === year && balance.month === month)
              serie.data.push(foundBalance ? formatValueToNumberByType(foundBalance.value, dimensionData.type) : 0)
            }
            series.push(serie)
          })
      })
    }

    return series
  }

  const items = useMemo(
    () =>
      dashboard &&
      dashboard?.keyFigures?.map((d: any) => ({
        key: d,
        label: t(`globalKeyFigureStatement:${d}`),
        children: (
          <CompetitorChart
            categories={generatePeriodGroupPeriods({
              id: dayjs(startDate).year(),
              startDate: startDate || '',
              endDate: endDate || ''
            } as PeriodGroup).map(period => `${period.month} / ${period.year}`)}
            series={generateKeyFigureChartSeries(d)}
          />
        )
      })),
    [dashboard?.keyFigures, keyfigureStatements, periodGroups, dimensionKeyfigureStatements]
  )

  if (!dashboard) return null

  return (
    <div style={{ marginTop: 16 }}>
      {dashboard?.keyFigures && dashboard?.keyFigures?.length > 0 && <Collapse items={items} destroyInactivePanel />}
    </div>
  )
}

export default MultiPurposeDashboardKeyFigureCharts
