import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps } from 'antd'
import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import {
  updateCompanyDashboardLayouts,
  deleteCompanyDashboardRequest,
  getDataForCompanyDashboard,
  getCompanyDashboardRequest
} from '../../../redux/context/dashboard/company/actions'
import {
  companyDashboardLayoutsSelector,
  companyDashboardLoadingSelector,
  companyDashboardSelector
} from '../../../redux/context/dashboard/company/selector'
import GridItem from './components/GridItem'
import { contextRequest } from '../../../redux/context/actions'
import { getFormulasRequest } from '../../../redux/context/formulas/actions'
import DashboardModal from './components/DashboardModal'
import { dimensionQuerySelector, filtersSelector } from '../../../redux/context/filters/selectors'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import { NewDasboadItemModal } from './components/DashboardItemEdit/DasboadItemModal'
import {
  CompanyDashboardContextType,
  CompanyDashboardItemContext
} from './components/DashboardItemEdit/DashboardItemProvider'
import { setActiveDashboard } from '../../../redux/pages/dashboard/companyDashboard/actions'
import { companyDashboardPageSelector } from '../../../redux/pages/dashboard/companyDashboard/selectors'
import deleteConfirmModal from '../../../components/Modal/deleteConfirmModal'
import { AppDispatch } from '../../../redux/store'
import { loadingSelector } from '../../../redux/loading/selectors'
import ReportSelect from '../../../components/Misc/ReportSelect'
import './_CompanyDashboard.sass'
import { resetReports } from '../../../redux/context/reports/actions'

const ResponsiveGridLayout = WidthProvider(Responsive)

const CompanyDashboard: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const { activeDashboard: activeDashboardId } = useSelector(companyDashboardPageSelector)
  const companyDashboards = useSelector(companyDashboardSelector)
  const { budgetingScenario, dimensions, periodGroups } = useSelector(filtersSelector)
  const companyDashboardLayoutsUnfiltered = useSelector(companyDashboardLayoutsSelector)
  const companyDashboardLoading = useSelector(companyDashboardLoadingSelector)
  const companyDashboardLayouts = _.cloneDeep(companyDashboardLayoutsUnfiltered)
  companyDashboardLayouts && delete companyDashboardLayouts.lastModified
  const [modalVisible, setModalVisible] = useState<'edit' | 'new' | false>(false)
  const { dimensions: dimensionsLoading } = useSelector(loadingSelector)
  const activeDashboard = companyDashboards?.find(c => c.id === Number(activeDashboardId))
  const dimensionQuery = useSelector(dimensionQuerySelector)

  const { handleOpen } = useContext(CompanyDashboardItemContext) as CompanyDashboardContextType

  const initData = () => {
    dispatch(resetReports())
    if (companyDashboards && companyDashboards[0] && !activeDashboard) {
      dispatch(setActiveDashboard(companyDashboards[0].id))
    }
  }

  const getDashboards = () => {
    companyId && dispatch(getCompanyDashboardRequest(companyId))
  }

  const getDashboardData = () => {
    // TODO: loading hässäkkä
    if ((activeDashboardId && dimensions?.length && !dimensionsLoading) || (activeDashboardId && !dimensions?.length)) {
      dispatch(getDataForCompanyDashboard(activeDashboard))
      dispatch(contextRequest(getFormulasRequest))
    }
  }

  useEffect(getDashboards, [companyId, budgetingScenario, dimensions, periodGroups])
  useEffect(initData, [companyDashboards, budgetingScenario, dimensions, periodGroups, dispatch])
  useEffect(getDashboardData, [activeDashboardId, budgetingScenario, dimensionQuery, periodGroups, dispatch])

  const deleteDashboard = async () => {
    if (companyId && activeDashboard && activeDashboardId) {
      dispatch(deleteCompanyDashboardRequest(companyId, activeDashboardId))
    }
    dispatch(setActiveDashboard(null))
  }

  const handleMenuClick = ({ key }: any) => {
    switch (key) {
      case 'edit':
        setModalVisible('edit')
        break
      case 'delete':
        deleteConfirmModal({
          content: activeDashboard?.name,
          onOk: () => deleteDashboard()
        })
        break
      default:
        break
    }
  }

  const onChange = (id: number) => {
    dispatch(setActiveDashboard(id))
  }

  const reportButtonItems: MenuProps['items'] = [
    {
      key: 'edit',
      icon: <EditOutlined />,
      label: t('global:edit')
    },
    {
      key: 'delete',
      icon: <DeleteOutlined />,
      label: t('global:delete')
    }
  ]

  return (
    <PageHeaderWrapper
      title={t('menu:/settings/accountant/subscriptions/company-dashboard')}
      subTitle={<DocumentationLink />}
      extra={[
        <ReportSelect activeDashboard={activeDashboardId} dashboards={companyDashboards} onChange={onChange} />,
        <Button key="new" icon={<PlusOutlined />} onClick={() => setModalVisible('new')}>
          {t('dashboardPage:new-dashboard')}
        </Button>,
        <Dropdown.Button
          key="dropDown"
          menu={{ items: reportButtonItems, onClick: handleMenuClick }}
          disabled={!activeDashboard}
          onClick={() => {
            handleOpen()
          }}
        >
          <PlusOutlined />
          {t('dashboardPage:add-new-item')}
        </Dropdown.Button>
      ]}
      ghost={false}
    >
      <DashboardModal dashboard={activeDashboard} visible={modalVisible} setVisible={setModalVisible} key="1" />
      <NewDasboadItemModal />
      <ResponsiveGridLayout
        isResizable
        className={classNames('layout', { animated: !companyDashboardLoading })}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={30}
        margin={[16, 16]}
        style={{ position: 'relative', margin: -16 }}
        draggableHandle=".grid-item-header"
        layouts={activeDashboard && (activeDashboard.layouts as ReactGridLayout.Layouts)}
        onLayoutChange={(currentLayout, allLayouts) => {
          if (!_.isEqual(companyDashboardLayouts, allLayouts)) {
            activeDashboard &&
              companyId &&
              !companyDashboardLoading &&
              dispatch(updateCompanyDashboardLayouts(activeDashboard, allLayouts, currentLayout, companyId))
          }
        }}
      >
        {activeDashboard?.items?.map(item => {
          return (
            item && (
              <div
                key={item.id?.toString()}
                data-grid={
                  activeDashboard.layouts?.['lg' || activeDashboard.layouts?.lastModified]?.find(
                    f => Number(f.i) === item.id
                  ) || {
                    x: 0,
                    y: Infinity,
                    w: 6,
                    h: 10
                  }
                }
              >
                <GridItem item={item} companyDashboardId={activeDashboard.id} />
              </div>
            )
          )
        })}
      </ResponsiveGridLayout>
    </PageHeaderWrapper>
  )
}

export default CompanyDashboard
