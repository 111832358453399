import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import { ReportDataType, ReportRow } from '../../../context/reports/types'
import { pagesSelector } from '../../selectors'
import { MultiDashboard } from './types'
import { periodGroupLabel } from '../../../context/fiscalYears/utils'
import { PeriodGroup } from '../../../../types/periodGroup/PeriodGroup'

export const multiPurposeDashboardPageSelector = createSelector(pagesSelector, pages => pages.multiPurposeDashboard)
export const multiPurposeActiveDashboardSelector = createSelector(
  multiPurposeDashboardPageSelector,
  ({ dashboards, activeDashboard }) => dashboards.find(d => d.id === activeDashboard)
)
export const multiPurposeDashboardsSelector = createSelector(multiPurposeDashboardPageSelector, ({ dashboards }) => {
  return dashboards.sort((a, b) => a.order - b.order)
})

export const multiPurposeDashboardSelector = createSelector(
  multiPurposeDashboardPageSelector,
  ({ dashboards, activeDashboard }) => dashboards?.find(d => d.id === activeDashboard)
)

const getKeyFigureValue = (keyFigure: ReportRow, periodGroupId: number): number | undefined => {
  if (!keyFigure.actuals) return undefined
  const balance = keyFigure.actuals.find(b => b.groupId === periodGroupId)
  return balance?.value
}

const getTableRowValue = (keyFigure: ReportRow, { startDate, endDate }: MultiDashboard) => {
  const value = getKeyFigureValue(keyFigure, dayjs(startDate).year())
  const historyValue = getKeyFigureValue(keyFigure, -dayjs(startDate).year())

  return {
    currentValue: {
      label: dayjs(startDate).year(),
      type: keyFigure.type,
      value
    },
    historyValue: {
      label: periodGroupLabel(
        {
          startDate: dayjs(startDate).subtract(12, 'M') || '',
          endDate: dayjs(endDate).subtract(12, 'M') || ''
        } as unknown as PeriodGroup,
        ReportDataType.actuals
      ),
      type: keyFigure.type,
      value: historyValue
    }
  }
}

export const getTableRows = (report: ReportRow[], dashboard: MultiDashboard) => {
  return report?.reduce((rows, keyFigure) => {
    return { ...rows, [keyFigure.id]: getTableRowValue(keyFigure, dashboard) }
  }, {})
}
